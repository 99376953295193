
.Ideas-content-class {
    padding: 50px;
}

.Ideas-text-class p{
    font-size: calc(10px + 1vmin);
}

.An-App-ideas h2{
    animation: Appear-from-above 0.8s
}