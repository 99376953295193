
.About-content-class {
    display: flex;
    justify-content: center;
    padding: 50px;
}

.About-content-class .p-image {
    margin: 50px
}
.About-text-class {
    width: 40%;
    font-size: calc(10px + 1vmin);
    display: initial;
}

.About-text-class .Skill-List-Class {
    margin-top: 20px;

}
.About-text-class .p-carousel-item {

    align-items: center;
    display: grid;

}
.About-text-class .p-carousel-item span{
    display: block;
}
.About-text-class .p-highlight button{
    background-color: rgba(97, 248, 255, 0.41) !important;
}
.About-text-class b {
    font-size: larger;
}

.About-text-class a{
    color: white;
}
.An-App-about h2{
    animation: Appear-from-above 0.8s;
}


.An-App-about .About-text-class{
    animation: Appear-from-left-long 0.8s;
}

.An-App-about .About-text-class ul{
    animation: Appear-from-left-long 1.4s;
}

.An-App-about .About-photo-class{
    animation: Appear-from-right-long 0.8s;
    display: block;
    align-self: flex-start;
    object-fit: contain;
    max-width: 80%;
    height: auto;
}

.About-photo-class img {
    object-fit: contain;
    max-width: inherit;
}

@media screen and (max-width: 960px) {
    .About-content-class {
        display: block;
    }
    .About-content-class .p-image {
        margin: 0 50px 0 50px;
    }
}