.App-footer{
    background-image: linear-gradient(180deg, #1b1e23, #293754);
    display: flex  !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    color: white;
    width: 100% !important;
    max-width:inherit !important;
}
.App-footer .p-menubar {
    background-color: transparent !important;
}

.Footer-contact-content {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 3rem;
}
.Footer-decoration {
    color: white;
    width: 100%;
}

.Footer-legal-content {
    font-size: calc(8px + 1vmin);
}
.Footer-legal-content {
    margin: 1rem !important;
}

.Contact-buttons {
    display: table-column;
}

.SocialMedia-div {
    display: block;
    align-self: center;
    align-items: center;
    padding-inline: 3rem;
}
.SocialMedia-div p {
    padding-left: 0.5rem;
    margin: 0;
}

.Recruiting-div{
    /*padding: 50px;*/
    width: 70vmin;
    display: block;
}
.Recruiting-div h3 {
    text-decoration: underline #00f7ff;
}

.Recruiting-text {
    font-size: smaller;
    margin: 0;
}
.Recruiting-text b{
    font-size: larger;
}
.Footer-legal-content a{
    color: white;
}
