.Work-content-class {
    padding: 50px 280px;
}

@media screen and (max-width: 960px) {
    .Work-content-class {
        padding: 5px;
    }
}

.Work-content-class p{
    font-size: calc(10px + 1vmin);
}

.Work-class .Datascroller-Class .p-datascroller > * {
    background-color: inherit;
    border: none;
    color: white;
}

.Work-class .p-datascroller-header {
    font-size: 20px;
}

.Work-class .p-datascroller-content {

}

.Work-class .p-datascroller-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
}

.Work-class .project-card {
    background-color: #293754;
    border-radius: 15px;
}

.Work-class .project-card img {
    object-fit: contain;
    width: 40%;
    border-radius: 15px 15px 0 0 ;
}

.project-card .project-name {
    font-size: calc(10px + 2vmin);
    font-weight: bolder;
}

.Work-class .p-button:hover {
    background-color: rgba(0, 247, 255, 0.75);
}

.Work-class .p-datascroller-footer {
    padding: 20px;
}

.Work-class .p-button {
    background-color: #293754;
    border: none;
    /*color: white !important;*/
}

.project-detail {
    color: #8f92a5;
    display: flow;
}
.project-description {
    font-weight: lighter;
    overflow: clip;
    /*display:flow;*/
    padding-bottom: 10px;
}

/***************************/
.card {
    --bg: #f7f7f8;
    --hover-bg: #293754;
    --hover-text: white;
    text-align: center;
    background: var(--bg);
    position: relative;
    overflow: hidden;
    transition: .3s cubic-bezier(.6,.4,0,1),transform .15s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card > :not(.project-span) {
    transition: .3s cubic-bezier(.6,.4,0,1);
}


.card .project-span {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--hover-text);
    border-radius: 5px;
    font-weight: bold;
    top: 100%;
    transition: all .3s cubic-bezier(.6,.4,0,1);
}

.card:hover .project-span {
    top: 0;
    font-size: 1.2em;
}


.card:hover {
    background: var(--hover-bg);
}

.card:hover>div,.card:hover>strong{
    opacity: 0;
}

.card .project-span {
    display: block;
    padding: 15px;
}

.project-span a {
    color: inherit;
}
.project-span .span-footer {
    position: absolute;
    bottom: 5%;
    width: 95%;
    display: -webkit-box;
    gap: 30px;
}

.chiplist-class .p-menubar {
    background-color: transparent;
    border: none;
}

.chiplist-class .p-menubar .p-menuitem-link{
    background-color: transparent;
    border: none;
    cursor:default;
}
.chiplist-class .p-menubar .p-menuitem-link:active{
    background-color: transparent;
    border: none;
    cursor:default;
}

.chiplist-class ul {
    gap: 20px;
    padding-left: 20px;
}
.chiplist-class ul li {
    /*padding: 5px;*/
    font-size: 15px;
    width: 100px;
}

.chiplist-class ul li>*:hover {
    background-color: transparent;
}

.chiplist-class .p-menubar-button {
    display: none;
}

.project-span a>i {
    font-size: 30px
}
 /*************************/

.An-App-work h2 {
    animation: Appear-from-above 0.8s;
}

.An-App-work .Datascroller-Class {
    animation: Appear-from-below 0.8s;
}

.project-span .Work-Tech-List {
    width: inherit;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: block;
}

.Work-Tech-List .p-carousel-items-content{
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.Work-Tech-List .p-carousel-content {
    width: inherit;
}

.Work-Tech-List .p-highlight button{
    background-color: rgba(97, 248, 255, 0.41) !important;
}

@media screen and (max-width: 960px) {
    .Work-Tech-List .p-carousel-items-content{
        display: block;
    }
}