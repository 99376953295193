* {
    box-sizing: border-box;
}
.MainContent-class{
    /*padding: 100px;*/
    display: flow;
    justify-content: center;
    /*display: ;*/
    /*max-width: 100%;*/
    color: white;
    background-color: #2b2b2b;
}
.Timeline-div{
    /*display: none;*/
    /*position: fixed;*/
    display:flex;
    /*float: left;*/
}

.Timeline-class {
    /*position: relative;*/
    /*float: left*/
    /*position: fixed;*/
    display: flex;
    /*flex-direction: column;*/
    align-items: baseline;
    width: fit-content;
    float: left;
    /*justify-content: center;*/
}

.Timeline-class .p-timeline {
    /*float: left;*/
    /*display:*/
    /*disp*/
    ;
}

.MainContent-class h2 {
    /*font-size: calc(10px + 2vmin);*/
    /*margin: 0px;*/
}

.Inv-Class {
    visibility: hidden;
}