.Navbar-class {
    color: blueviolet;
    /*padding: 100px;*/
    display: flex;
    /*position: fixed;*/
    /*transition: 5s;*/
    width: 100%;
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*color: #61dafb;*/
    /*background: #282c34;*/
    /*border: 0px;*/
}
.Navbar-class .button {
    box-shadow: none !important;
    /*color: white !important;*/
}

.Navbar-class .p-menuitem{
    font: calc(5px + 2vmin) italic bolder;
}
.Navbar-class:not(#hidden-navbar) .p-menuitem{
    /*font: calc(5px + 2vmin) italic bolder;*/
    animation: Appear-from-above .5s;
}

.Navbar-class.p-menubar{
    /*padding: 100px;*/
    background: inherit !important;
    border: 0px !important;
    border-radius: 0px !important;
}
.Navbar-class.p-menubar ul {
    position: absolute;
    right: 0%;
}

#hidden-navbar {
    visibility: hidden;
    position: fixed;
    /*background-color: #12151a !important;*/
    background-image: linear-gradient(180deg, #293754, #2b2b2b) !important;
    opacity: 0.9;

    /*animation: Appear-from-above  0.3s ;*/
    /*transition: .5s;*/

    /*display:grid;*/
    /*place-items: center;*/

    /*-webkit-transition: color .25s linear;*/
    /*transition: color .25s linear;*/
    /*transition: background-color .15s linear .1s;*/
}
.Animation-class {
    animation: Appear-from-above  0.3s ;
}

#hidden-navbar.p-menubar {
    /*display: grid;*/
    /*place-items: center;*/
    padding: 30px;
}

#hidden-navbar.p-menubar ul {
    /*position: absolute;*/
    /*background-color: inherit;*/
    right: 50%;
    transform: translate(50%);
    /*padding: 100px*/
    /*display: flex;*/
    /*flex-direction: column;*/
    align-items: center;
    alignment: center;
    justify-content: center ;
}

#hidden-navbar .p-menuitem-text{
    color: #00f7ff;
    /*border: none;*/
}
/*.p-menubar-button:hover{*/
/*    border: 2px blueviolet;*/
/*}*/
.Navbar-First {
    display: flex;
}
.Navbar-First:hover {

}

.App-logo-navbar {
    height: 5vmin;
    /*pointer-events:  ;*/
}

.PageTitle-Navbar {
    color: white !important;
    align-self: center;
    font-size: 2vmin !important;
    display: block;
    font-weight: bolder;
    animation: Appear-from-above 1.8s;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo-navbar {
        animation: App-logo-navbar-spin infinite 10s linear;
    }
}

@keyframes App-logo-navbar-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Navbar-class-header {
}

.Navbar-class-page {
    display: flex;
    position: fixed;
}


/* === removing default button style ===*/
.Navbar-class  .button {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
}

/* button styling */
.Navbar-class .button {
    --border-right: 6px;
    --text-stroke-color: rgba(255,255,255,0.6);
    --animation-color: #00f7ff;
    --fs-size: 2em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: inherit;
    /*font-family: "Arial";*/
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.Navbar-class .hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    /*border-right: var(--border-right) solid var(--animation-color);*/
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.Navbar-class .button:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color))
}

@media screen and (max-width: 960px) {
    .Navbar-class.p-menubar.p-menubar-mobile-active .p-menubar-root-list{
        display: flex;
        flex-direction: inherit;
        left: inherit;
        top: 80%;
    }
}
