/*@import url(font-awesome-all.min.css);*/

/*
	Massively by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Wrapper */

	#wrapper {
		background-color: #212931;
		background-image: url("../../images/overlay.png"), linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("../../images/bg.jpg");
		background-size: auto,								auto,														100% auto;
		background-position: center,								center,														top center;
		background-repeat: repeat,								no-repeat,													no-repeat;
		background-attachment: fixed,								fixed,														fixed;
	}

		#wrapper.fade-in:before {
			display: none;
		}

/* Intro */

	body.is-preload #intro {
		opacity: 1;
	}

		body.is-preload #intro:not(.hidden) + #header + #nav {
			-moz-transform: none;
			-webkit-transform: none;
			-ms-transform: none;
			transform: none;
			opacity: 1;
		}