.SocialMedia-Buttons.p-menubar.p-component {
    justify-content: center;
    background-color: transparent !important;
    border: none;
}

.SocialMedia-Buttons ul * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: inherit;
}

.SocialMedia-Buttons ul *:focus,
.SocialMedia-Buttons ul *:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
}


.SocialMedia-Buttons ul  {
    list-style: none;
}

.SocialMedia-Buttons ul .icon {
    position: relative;
    background: #272f3f;
    border-radius: 50%;
    padding: 15px;
    margin: 10px;
    width: 50px;
    height: 50px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.SocialMedia-Buttons ul .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #ffffff;
    color: #ffffff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.SocialMedia-Buttons ul .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.SocialMedia-Buttons ul .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.SocialMedia-Buttons ul .icon:hover span,
.SocialMedia-Buttons ul .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.SocialMedia-Buttons ul .facebook:hover,
.SocialMedia-Buttons ul .facebook:hover .tooltip,
.SocialMedia-Buttons ul .facebook:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
}

.SocialMedia-Buttons ul .twitter:hover,
.SocialMedia-Buttons ul .twitter:hover .tooltip,
.SocialMedia-Buttons ul .twitter:hover .tooltip::before {
    background: #1DA1F2;
    color: #ffffff;
}

.SocialMedia-Buttons ul .instagram:hover,
.SocialMedia-Buttons ul .instagram:hover .tooltip,
.SocialMedia-Buttons ul .instagram:hover .tooltip::before {
    background: #E4405F;
    color: #ffffff;
}

.SocialMedia-Buttons ul .github:hover,
.SocialMedia-Buttons ul .github:hover .tooltip,
.SocialMedia-Buttons ul .github:hover .tooltip::before {
    background: #333333;
    color: #ffffff;
}

.SocialMedia-Buttons ul .youtube:hover,
.SocialMedia-Buttons ul .youtube:hover .tooltip,
.SocialMedia-Buttons ul .youtube:hover .tooltip::before {
    background: #3c3f41;
    color: #ffffff;
}

.SocialMedia-Buttons ul .linkedin:hover,
.SocialMedia-Buttons ul .linkedin:hover .tooltip,
.SocialMedia-Buttons ul .linkedin:hover .tooltip::before {
    background: #1877F2;
    color: #ffffff;
}

.SocialMedia-Buttons ul .email:hover,
.SocialMedia-Buttons ul .email:hover .tooltip,
.SocialMedia-Buttons ul .email:hover .tooltip::before {
    background: #71777d;
    color: #ffffff;
}