html {
    scroll-behavior: smooth;
}
:target:before {
    content: "";
    display: block;
    height: 50px;
    margin: -100px 0 0;
}

.App {
    text-align: center;
    line-height: 1.7;

}

@keyframes Appear-from-above {
    from {
        transform: translate(0, -50px);
    }
    to {
        transform: translate(0, 0);
        visibility: visible ;
    }
}

@keyframes Appear-from-below {
    from {
        transform: translate(0, 50px);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Appear-from-right {
    from {
        transform: translate(50px, 0);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Appear-from-left {
    from {
        transform: translate(-50px, 0);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Disappear-from-below {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(0, -50px);
        /*visibility: hidden ;*/
        /*position:fixed;*/
    }
}

@keyframes Appear-from-above-long {
    from {
        transform: translate(0, -250px);
    }
    to {
        transform: translate(0, 0);
        visibility: visible ;
    }
}

@keyframes Appear-from-above-long-long {
    from {
        transform: translate(0, -850px);
    }
    to {
        transform: translate(0, 0);
        visibility: visible ;
    }
}
@keyframes Appear-from-below-long {
    from {
        transform: translate(0, 550px);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Appear-from-right-long {
    from {
        transform: translate(250px, 0);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Appear-from-left-long {
    from {
        transform: translate(-250px, 0);
    }
    to {
        transform: translate(0, 0);
        /*visibility: visible;*/
    }
}

@keyframes Disappear-from-below-long {
    from {
        transform: translate(0px, 0px);
    }
    to {
        transform: translate(0, -250px);
        /*visibility: hidden ;*/
        /*position:fixed;*/
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-13 12:41:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-top {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
}
@keyframes scale-out-top {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        opacity: 1;
    }
}




@media (prefers-reduced-motion: no-preference) {
    .An-App-Above {
        animation: Appear-from-above 0.8s;
    }
    .An-App-Below {
        animation: Appear-from-below 0.8s;
    }
    .An-App-Right {
        animation: Appear-from-right 0.8s;
    }
    .An-App-Left {
        animation: Appear-from-left 0.8s;
    }
    .An-DisApp-Below {
        animation: Disappear-from-below 0.8s;
    }

    .An-App-Above-Long {
        animation: Appear-from-above-long 0.8s;
    }
    .An-App-Above-Long-Long {
        animation: Appear-from-above-long-long 1.8s;
    }
    .An-App-Below-Long {
        animation: Appear-from-below-long 0.8s;
    }
    .An-App-Below-Long-Long {
        animation: Appear-from-below-long 1.8s;
    }
    .An-App-Right-Long {
        animation: Appear-from-right-long 0.8s;
    }
    .An-App-Left-Long {
        animation: Appear-from-left-long 0.8s;
    }
    .An-DisApp-Below-Long {
        animation: Disappear-from-below-long 0.8s;
    }
}

.scale-out-top {
    -webkit-animation: scale-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.5s both;
    animation: scale-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) 2.5s both;
}

#LoadingScreen {
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100%;
    background-color: #293754;
    color: white;
}

#Page {
}

h2 {
    font-size: calc(15px + 2vmin) !important;
    text-decoration: underline #00f7ff 4px;
    text-underline-offset: 1.5rem;
;
}

@media screen and (max-width: 960px) {
    .p-menubar .p-menubar-root-list {
        background-color: transparent !important;
        width: inherit !important;
        left: 5px;
    }
}

.p-link {
    color:white!important;
}